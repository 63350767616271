import * as React from 'react';
import { dev_logo, genii_logo } from '../../utilities/images';

export interface IContactProps {
}

export default function Contact(props: IContactProps) {
  return (
    <div className="container pt-8 mx-auto md:px-6">
      <section className="mb-32 text-center">
        <div className="py-12 md:px-12">
          <div className="container mx-auto xl:px-32">
            <div className="grid items-center lg:grid-cols-2">
              <div className="mb-12 md:mt-12 lg:mt-0 lg:mb-0">
                <div
                  className="relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[30px] dark:bg-[hsla(0,0%,5%,0.7)] dark:shadow-black/20 md:px-12 lg:-mr-14">
                  <h2 className="mb-12 text-3xl font-bold dark:text-white">Contact us</h2>


                  <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                    <div>
                      <img className="w-auto h-auto rounded-xl  " src={genii_logo} alt="brand logo" />
                    </div>
                    <div>

                      <h1 className="text-lg font-bold dark:text-white">+91 9415008354</h1>
                      <h1 className="text-lg font-light dark:text-white">abhishek@gen2.in</h1>
                      <br />
                      <p className="dark:text-white">52-53 Chakrapuri, Paper Mill Colony</p>
                      <p className="dark:text-white">Nishatganj, Lucknow.</p>
                      <p className="dark:text-white">226006</p>

                    </div>
                  </div>
                </div>
              </div>
              <div className="md:mb-12 lg:mb-0">
                <div
                  className="relative h-[700px] rounded-lg shadow-lg dark:shadow-black/20">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.240159869285!2d80.96271487641263!3d26.86410987667618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfdc67bb7ddc9%3A0x9392fb9a83c0308!2sGen%20II!5e0!3m2!1sen!2sin!4v1693061585681!5m2!1sen!2sin"
                    className="absolute left-0 top-0 h-full w-full rounded-lg"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}
