import * as React from 'react';
import { dev_logo } from '../../utilities/images';
import { CONSTANTS } from '../../utilities/constants';

export interface IServicesProps {
}

export default function Services(props: IServicesProps) {
  return (
    <div>
      <section className="bg-white dark:bg-black">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">{'Services'}</h2>
            <p className="mb-8 font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400">{"There are various ways we can be of assistance as we provide these soltuions"}</p>
          </div>

          <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-6">
            {
              CONSTANTS.SERVICES.map(servicesItem => (
                <div className="grid grid-rows-3 grid-flow-col gap-4 bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-900 p-8 rounded-md">
                  <div className="row-span-3">
                    <img style={{ width: 'auto', height: 'auto', maxWidth: 140 }} src={servicesItem.logo} alt="brand logo" />
                  </div>
                  <div className="row-span-2 col-span-2 dark:text-white font-thin">{servicesItem.subtitle}</div>
                  <div className="col-span-2">
                    <h1 className="text-lg font-semibold dark:text-white">{servicesItem.title}</h1>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>
    </div>
  );
}
