import {
    hp_logo,
    acer_logo,
    dell_logo,
    hikvision_logo,
    honeywell_logo,
    lenovo_logo,
    lg_logo,
    microsoft_logo,
    panasonic_logo,
    samsung_logo,
    sophos_logo,
    dev_logo,
    distribution_logo,
    retail_logo
} from './images';


export const CONSTANTS = {
    NAVBAR_MENU: [
        // { key: 'HOME', title: "Home", to: "/" },
        { key: 'SERVICES', title: "Services", to: "/services" },
        { key: 'PARTNERS', title: "Partners", to: "/partners" },
        // { key: 'TESTIMONIALS', title: "Testimonials", to: "/testimonials" },
        // { key: 'CONTACT', title: "Contact Us", to: "/contact" },
    ],


    TESTIMONIALS: [
        {
            TITLE: "Speechless with how easy this was to integrate",
            PARAGRAPHS: [
                "I recently got my hands on Flowbite Pro, and holy crap, I'm speechless with how easy this was to integrate within my application. Most templates are a pain, code is scattered, and near impossible to theme.",
                "Flowbite has code in one place and I'm not joking when I say it took me a matter of minutes to copy the code, customise it and integrate within a Laravel + Vue application.",
                "If you care for your time, I hands down would go with this."
            ],
            AVATAR: "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/karen-nelson.png",
            AUTHOR: "Bonnie Green",
            ABOUT_AUTHOR: "Developer at Open AI"
        },
        {
            TITLE: "Speechless with how easy this was to integrate",
            PARAGRAPHS: [
                "I recently got my hands on Flowbite Pro, and holy crap, I'm speechless with how easy this was to integrate within my application. Most templates are a pain, code is scattered, and near impossible to theme.",
                "Flowbite has code in one place and I'm not joking when I say it took me a matter of minutes to copy the code, customise it and integrate within a Laravel + Vue application.",
                "If you care for your time, I hands down would go with this."
            ],
            AVATAR: "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/karen-nelson.png",
            AUTHOR: "Bonnie Green",
            ABOUT_AUTHOR: "Developer at Open AI"
        },
        {
            TITLE: "Speechless with how easy this was to integrate",
            PARAGRAPHS: [
                "I recently got my hands on Flowbite Pro, and holy crap, I'm speechless with how easy this was to integrate within my application. Most templates are a pain, code is scattered, and near impossible to theme.",
                "Flowbite has code in one place and I'm not joking when I say it took me a matter of minutes to copy the code, customise it and integrate within a Laravel + Vue application.",
                "If you care for your time, I hands down would go with this."
            ],
            AVATAR: "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/karen-nelson.png",
            AUTHOR: "Bonnie Green",
            ABOUT_AUTHOR: "Developer at Open AI"
        },
        {
            TITLE: "Speechless with how easy this was to integrate",
            PARAGRAPHS: [
                "I recently got my hands on Flowbite Pro, and holy crap, I'm speechless with how easy this was to integrate within my application. Most templates are a pain, code is scattered, and near impossible to theme.",
                "Flowbite has code in one place and I'm not joking when I say it took me a matter of minutes to copy the code, customise it and integrate within a Laravel + Vue application.",
                "If you care for your time, I hands down would go with this."
            ],
            AVATAR: "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/karen-nelson.png",
            AUTHOR: "Bonnie Green",
            ABOUT_AUTHOR: "Developer at Open AI"
        }
    ],
    PARTNERS: [
        { title: "HP", logo: hp_logo },
        { title: "Acer", logo: acer_logo },
        { title: "Dell", logo: dell_logo },
        { title: "Hikvision", logo: hikvision_logo },
        { title: "Honeywell", logo: honeywell_logo },
        { title: "Lenovo", logo: lenovo_logo },
        { title: "LG", logo: lg_logo },
        { title: "Microsoft", logo: microsoft_logo },
        { title: "Panasonic", logo: panasonic_logo },
        { title: "Samsung", logo: samsung_logo },
        { title: "Sophos", logo: sophos_logo }
    ],
    SERVICES: [
        { title: "Software Development", logo: dev_logo, subtitle: "At GEN II, we approach each engagement by starting with a detailed discussion of your business and technology challenges and goals. We then develop a strategy that will shape our engagement, ensure success, and provide you with the most exemplary software development services." },
        { title: "Electronics Distributor", logo: distribution_logo, subtitle: "For all the IT needs a small startup or a big business may need, they can come to us. We provide complete hardware IT solutions to get you started." },
        { title: "Retail", logo: retail_logo, subtitle: "We have various retail outlets across Lucknow to provide the customers with the retail expreience where they can come and experience the product before they make a decission to buy it. It helps us connect better with our customers." },
    ],
    JUMBOTRON_HOME: {
        TITLE: "Hardware and software Solutions",
        SUBTITLE: "We provide complete solutions for small to large scale businesses for their software and hardware needs",
        BUTTON_TITLE: "Know More about our services"
    }
}