export const hp_logo = require('../assets/companyLogos/hp.png')
export const acer_logo = require('../assets/companyLogos/acer.png')
export const dell_logo = require('../assets/companyLogos/dell.png')
export const hikvision_logo = require('../assets/companyLogos/hikvision.png')
export const honeywell_logo = require('../assets/companyLogos/honeywell.png')
export const lenovo_logo = require('../assets/companyLogos/lenovo.png')
export const lg_logo = require('../assets/companyLogos/lg.png')
export const microsoft_logo = require('../assets/companyLogos/microsoft.png')
export const panasonic_logo = require('../assets/companyLogos/panasonic.png')
export const samsung_logo = require('../assets/companyLogos/samsung.png')
export const sophos_logo = require('../assets/companyLogos/sophos.png')

export const genii_logo = require('../assets/logo.jpg')
export const dev_logo = require('../assets/dev.png')
export const distribution_logo = require('../assets/distribution.png')
export const retail_logo = require('../assets/retail.png')