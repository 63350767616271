import * as React from 'react';
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Contact from '../pages/Contact/Contact';
import { CONSTANTS } from '../utilities/constants';
import Partners from '../pages/Partners/Partners';
import Services from '../pages/Services/Services';
import Testimonials from '../pages/Testimonials/Testimonials';
import CustomFooter from './CustomFooter';

export interface INavbarProps {
}

export interface INavbarButtonProps {
    title: string;
    action?: any;
    to: string;
    isBold?: boolean;
}

export default function Navbar(props: INavbarProps) {
    const { NAVBAR_MENU } = CONSTANTS
    const NavbarButton = (buttonProps: INavbarButtonProps) => (
        <Link to={buttonProps.to} className={`block mt-4 lg:inline-block lg:mt-0 text-white dark:text-black mr-4 text-base ${buttonProps.isBold ? "font-bold hover:font-extrabold" : "hover:font-medium"}`}>
            {buttonProps.title}
        </Link>
    )

    const renderNavbar = () => (
        <nav className="flex items-center justify-between flex-wrap p-4 bg-black dark:bg-white">
            <div className="flex items-center flex-shrink-0 text-white mr-6">
                <svg className="fill-current h-8 w-8 mr-2 dark:fill-black" width="54" height="54" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z" /></svg>
                <NavbarButton to={'/'} title={"GEN II"} isBold />
            </div>
            <div className="block lg:hidden">
                <button className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
                    <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
                </button>
            </div>
            <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                <div className="text-sm lg:flex-grow">
                    { 
                        NAVBAR_MENU.map((navbarItem, index) => (
                            <NavbarButton to={navbarItem.to} title={navbarItem.title} />
                        )) 
                    }
                </div>
                <div>
                    <NavbarButton to={'/contact'} title={"Contact Us"} isBold />
                </div>
            </div>
        </nav>
    )
    return (
        <Router>
            { renderNavbar() }
            <div className="h-screen w-screen bg-white dark:bg-black">
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/partners' element={<Partners />} />
                    <Route path='/services' element={<Services />} />
                    {/* <Route path='/testimonials' element={<Testimonials />} /> */}
                </Routes>
                <CustomFooter />
            </div>
        </Router>
    );
}
