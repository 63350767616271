import * as React from 'react';
import { CONSTANTS } from '../../utilities/constants';

export interface IPartnersProps {
}

export default function Partners(props: IPartnersProps) {
  const { PARTNERS } = CONSTANTS
  return (
    <div>
      <section className="bg-white dark:bg-black">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">{'Our Partners'}</h2>
            <p className="mb-8 font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400">{"There's no such thing as doing it alone, which is why we partner with industry-leading businesses"}</p>
          </div>
          <div className="grid mb-8 lg:mb-12 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5">
            {
              PARTNERS.map(partnerItem => (
                <figure className="flex flex-col justify-center items-center p-8 text-center bg-gray-100 md:p-8 dark:bg-gray-800 m-4 rounded-md hover:bg-gray-200 dark:hover:bg-gray-900">
                  <img style={{ width: 'auto', height: 'auto', maxWidth: 140 }} src={partnerItem.logo} alt="brand logo" />
                </figure>
              ))
            }
          </div>
        </div>
      </section>
    </div>
  );
}
