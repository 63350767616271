import * as React from 'react';
import { CONSTANTS } from '../../utilities/constants';
import { Link } from 'react-router-dom';

export interface IBrandsBox {
}

export default function BrandsBox(props: IBrandsBox) {
    const { PARTNERS } = CONSTANTS
    return (
        <div className="mt-6 ml-4">
            <section>
                
                <h1 className="mb-4 text-xl font-semibold leading-none tracking-tight dark:text-white text-gray-900 md:text-2xl lg:text2xl">Working with the our <mark className="px-2 text-white bg-blue-600 rounded dark:bg-blue-500">
                    <Link to="/partners">partners</Link>
                    </mark></h1>
                {/* <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.</p> */}

                <div className="max-w-screen-xl px-4 py-4 mx-auto text-center lg:py-4 lg:px-6">
                    <figure className="grid lg:grid-cols-6 md:grid-cols-4 justify-center items-center p-4 text-center bg-gray-50 border-b border-gray-200 md:p-12 lg:border-r">
                    {
                        PARTNERS.slice(0, 6).map(brandItem => (
                            // <figure className="flex flex-col justify-center items-center p-4 text-center bg-gray-50 border-b border-gray-200 md:p-12 lg:border-r">
                                <figcaption className="flex justify-center items-center space-x-3">
                                    <img style={{ width: 'auto', height: 'auto', maxWidth: 100 }} src={brandItem.logo} alt="brand logo" />
                                </figcaption>
                            // </figure>
                        ))
                    }
                    </figure>
                </div>
            </section>
        </div>
    );
}
