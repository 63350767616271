import * as React from 'react';
import { genii_logo } from '../../utilities/images';
import { Link } from 'react-router-dom';

export interface IJumbotronProps {
    title: string;
    subtitle: string;
    buttonTitle: string;
    buttonAction: any;
}

export default function AboutUsJumbotron (props: IJumbotronProps) {
  return (
    <div className='bg-slate-200 p-8'>
        <section className="max-w-screen-lg mx-auto flex justify-center mb-4">
            <img src={genii_logo} className="h-32 w-auto rounded-xl" alt="screenshot" />
        </section>
        {/* <img className="hidden h-10 w-auto lg:block" src={logo} alt={CONSTANTS.APP_INFO.APP_TITLE} /> */}
        <div className="max-w-screen-lg rounded-lg mx-auto text-center">
            <h2 className="text-xl leading-9 font-bold tracking-tight text-gray-800 sm:text-4xl sm:leading-10">
                {props.title}
            </h2>
            <p className='mt-4'>
                {props.subtitle}
            </p>
            <div className="mt-8 flex justify-center">
                <div className="inline-flex rounded-md outline outline-slate-600 hover:bg-slate-600 shadow">
                    <Link onClick={props.buttonAction} to="/services" className="text-slate-600 hover:text-white font-bold py-2 px-6">
                        {props.buttonTitle}
                    </Link>
                </div>
            </div>
        </div>
    </div>
  );
}
