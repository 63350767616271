import React from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';

function App() {
  return (
    <div>
      <Navbar />
    </div>
    // <div className="bg-teal-500 p-8 dark:bg-black">
    //   <h1 className="text-3xl font-bold underline dark:text-white">
    //     Hello world!
    //   </h1>
    // </div>
  );
}

export default App;
