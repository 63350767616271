import * as React from 'react';
import { genii_logo } from '../../utilities/images';
import { Link } from 'react-router-dom';
import TestimonialsJumbotron from './TestimonialsJumbotron';
import PartnersJumbotron from './PartnersJumbotron';
import ServicesJumbotron from './ServicesJumbotron';
import AboutUsJumbotron from './AboutUsJumbotron';
import { CONSTANTS } from '../../utilities/constants';
import BrandsBox from './BrandsBox';

export interface IHomeProps {
}

export default function Home(props: IHomeProps) {
  const { JUMBOTRON_HOME } = CONSTANTS
  return (
    <div>
      <section className="bg-white dark:bg-black">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
          {/* <TestimonialsJumbotron /> */}
          <div className="mb-8">
            <AboutUsJumbotron
              title={JUMBOTRON_HOME.TITLE}
              subtitle={JUMBOTRON_HOME.SUBTITLE}
              buttonTitle={JUMBOTRON_HOME.BUTTON_TITLE}
              buttonAction={() => { }}
            />
            <BrandsBox />
          </div>
          {/* <div className="mb-8">
            <PartnersJumbotron />
          </div> */}
          {/* <div className="mb-8">
            <ServicesJumbotron />
          </div> */}
        </div>
      </section>
    </div>
  );
}
